<template>
  <div>
    <form class="needs-validation" @submit.prevent="submitEditPrinter">
      <div class="form-group">
        <label for="printerData_serialNumber">{{$t('popularWords.sn')}}</label>
        <input
            id="printerData_serialNumber"
            v-model="printerData.serial_number"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.serial_number.$error }"
        />
        <div v-if="submitted && $v.printerData.serial_number.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.serial_number.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.printerData.serial_number.minLength">
                        {{$t('validatePhrases.minLength')}}: 3
                      </span>
          <span v-if="!$v.printerData.serial_number.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 35
                      </span>
        </div>
      </div>
      <div class="form-group">
        <label for="printerData_ip">IP</label>
        <input
            id="printerData_ip"
            v-model="printerData.ip"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.ip.$error }"
        />
        <div v-if="submitted && $v.printerData.ip.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.ip.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.printerData.ip.minLength">
                        {{$t('validatePhrases.minLength')}}: 10
                      </span>
          <span v-if="!$v.printerData.ip.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 20
                      </span>
        </div>
      </div>
      <div class="form-group">
        <label for="printerData_port">{{$t('views.printers.port')}}</label>
        <input
            id="printerData_port"
            v-model="printerData.port"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.port.$error }"
        />
        <div v-if="submitted && $v.printerData.port.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.port.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.printerData.port.minLength">
                        {{$t('validatePhrases.minLength')}}: 3
                      </span>
          <span v-if="!$v.printerData.port.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 6
                      </span>
        </div>
      </div>
      <div class="form-group">
        <label for="printerData_code">{{$t('views.printers.code')}}</label>
        <input
            id="printerData_code"
            v-model="printerData.code"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.code.$error }"
        />
        <div v-if="submitted && $v.printerData.code.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.code.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.printerData.code.minLength">
                        {{$t('validatePhrases.minLength')}}: 6
                      </span>
          <span v-if="!$v.printerData.code.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 6
                      </span>
        </div>
      </div>
      <div class="form-group">
        <label for="printerData_login">{{$t('views.printers.login')}}</label>
        <input
            id="printerData_login"
            v-model="printerData.login"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.login.$error }"
        />
        <div v-if="submitted && $v.printerData.login.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.login.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.printerData.login.minLength">
                        {{$t('validatePhrases.minLength')}}: 3
                      </span>
          <span v-if="!$v.printerData.login.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 65
                      </span>
        </div>
      </div>
      <div class="form-group">
        <label for="printerData_password">{{$t('views.printers.pass')}}</label>
        <input
            id="printerData_password"
            v-model="printerData.password"
            type="text"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.password.$error }"
        />
        <div v-if="submitted && $v.printerData.password.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.password.required">{{$t('validatePhrases.requiredField')}}</span>
          <span v-if="!$v.printerData.password.minLength">
                        {{$t('validatePhrases.minLength')}}: 3
                      </span>
          <span v-if="!$v.printerData.password.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 65
                      </span>
        </div>
      </div>
      <div class="form-group">
        <label for="printerData_notes">{{$t('views.printers.notes')}}</label>
        <textarea
            id="printerData_notes"
            v-model="printerData.notes"
            class="form-control form-control-sm"
            :class="{ 'is-invalid': submitted && $v.printerData.notes.$error }"
        ></textarea>
        <div v-if="submitted && $v.printerData.notes.$error" class="invalid-feedback">
          <span v-if="!$v.printerData.notes.maxLength">
                        {{$t('validatePhrases.maxLength')}}: 350
                      </span>
        </div>
      </div>

    </form>
  </div>
</template>

<script>

import {maxLength, minLength, required} from "vuelidate/lib/validators";
//import axios from "axios";

export default {
  name: "EditPrinter",
  components: {},
  data(){
    return {
      printerWorkSchedule:[],
      printerData: {
        code: "",
        ip: "",
        login: "",
        password: "",
        port: "",
        serial_number: "",
        notes: ""
      },
      submitted: false,
    }
  },
  props: {
    printer: Object,
    submitPrinter: Number
  },
  watch: {
    printer: function(prnt){
      let obj = {};
      for(let key in prnt){
        obj[key] = prnt[key];
      }
      this.printerData = obj;
    },
    submitPrinter: function(val){
      if(val > 0){
        this.submitEditPrinter();
      }
    }
  },
  computed: {

  },
  validations: {
    printerData: {
      serial_number: { required, minLength: minLength(3), maxLength: maxLength(35) },
      ip: { required, minLength: minLength(10), maxLength: maxLength(20) },
      port: { required, minLength: minLength(3), maxLength: maxLength(6) },
      code: { required, minLength: minLength(6), maxLength: maxLength(6) },
      login: { required, minLength: minLength(3), maxLength: maxLength(65) },
      password: { required, minLength: minLength(3), maxLength: maxLength(65) },
      notes: { maxLength: maxLength(350) }
    }
  },
  methods: {
    setWorkSchedule(val){
      this.printerData.printerWorkSchedule = val;
    },
    setPrice(val){
      this.printerData.printerPrice = val;
    },
    submitEditPrinter(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.printerData.$touch();
      if(!this.$v.printerData.$invalid){
        this.$emit('submitEditPrinter', this.printerData);
      }
    },
  },
  created() {

  }

};
</script>

<style scoped lang="css">

</style>
