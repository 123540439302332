<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">{{$t('views.printers.printerList')}}</div>

            <div class="row">
              <div class="col-lg-12">
                <div class="printers--actions">
                  <div class="search">
                    <div class="form-group">
                      <input
                        v-model="searchString"
                        type="text"
                        :placeholder="$t('navbar.search.text')"
                        class="form-control form-control-sm"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-lg-12">
                <table class="table table-hover" style="cursor: pointer">
                  <thead>
                    <tr>
                      <th>{{$t('popularWords.sn')}}</th>
                      <th>IP</th>
                      <th>{{$t('views.printers.port')}}</th>
                      <th>{{$t('views.printers.code')}}</th>
                      <th>{{$t('views.printers.isOn')}}</th>
                      <th>{{$t('popularWords.color')}}</th>
                      <th>{{$t('views.printers.production')}}</th>
                      <th>{{$t('views.printers.address')}}</th>
                      <th>{{$t('views.printers.notes')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in computedPrinters"
                      :key="item.serial_number"
                    >
                      <td @click="showEditPopup(Object.assign({}, item))">
                        {{ item.serial_number }}
                      </td>
                      <td @click="showEditPopup(Object.assign({}, item))">
                        {{ item.ip }}
                      </td>
                      <td @click="showEditPopup(Object.assign({}, item))">
                        {{ item.port }}
                      </td>
                      <td @click="showEditPopup(Object.assign({}, item))">
                        {{ item.code }}
                      </td>
                      <td @click="showEditPopup(Object.assign({}, item))">
                        {{ item.is_on }}
                      </td>
                      <td @click="showEditPopup(Object.assign({}, item))">
                        {{ item.is_color }}
                      </td>
                      <td @click="showEditPopup(Object.assign({}, item))">
                        {{ item.is_production }}
                      </td>
                      <td @click="showEditPopup(Object.assign({}, item))">
                        {{ item.printerLocation.address }}
                      </td>
                      <td>
                        <div @click="showShowNotesPopup(item.notes)" v-if="item.notes != '' && item.notes != null" class="btn btn-sm">
                          <i class="far fa-comment-dots"></i>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <popup
      :closeButton="editPrinterPopup.closeButton"
      :actionButton="editPrinterPopup.actionButton"
      :actionClass="editPrinterPopup.actionClass"
      :show="editPrinterPopup.show"
      @closePopup="closeEditPopup"
      @actionPopup="editPopupSubmit"
    >
      <div slot="header">Изменение принтера</div>
      <div slot="body">
        <edit-printer-sis
          :printer="editPrinter"
          :submit-printer="submitEditPrinter"
          @submitEditPrinter="savePrinter"
        ></edit-printer-sis>
      </div>
    </popup>

    <popup
      :closeButton="showNotesPopup.closeButton"
      :show="showNotesPopup.show"
      @closePopup="closeShowNotesPopup"
    >
      <div slot="header">{{$t('views.printers.notes')}}</div>
      <div slot="body">
        {{clickedNotes}}
      </div>
    </popup>

  </Layout>
</template>


<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Popup from "@/components/Popup";
import CopyArrayAndObject from "@/components/libraries/CopyArrayAndObject";
import EditPrinterSis from '../../../components/management/printers/EditPrinterSis.vue';

const copyArrObj = new CopyArrayAndObject();

/**
 * Printers component
 */
export default {
  components: {
    Popup,
    Layout,
    PageHeader,
    EditPrinterSis,
  },
  data() {
    return {
      printers: [],
      searchString: '',
      editPrinter: {},
      editPrinterPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
        actionButton: this.$t('popularWords.save'),
        actionClass: "btn-success"
      },
      submitted: false,
      submitEditPrinter: 0,
      showNotesPopup: {
        show: false,
        closeButton: this.$t('popularWords.close')
      },
      clickedNotes: "",
      title: this.$t('menuitems.management.list.printers'),
      items: [
        {
          text: this.$t('menuitems.management.text'),
        },
        {
          text: this.$t('menuitems.management.list.printers'),
          active: true
        }
      ],
    };
  },
  validations: {

  },
  computed: {
    computedPrinters(){
      let result = [];
      let searchStr = this.searchString;

      for(let key in this.printers){
        if(searchStr == ''){
          result[result.length] = this.printers[key];
        }else{
          let isSearched = false;
          let regexp = new RegExp(`${searchStr}`, 'i');
          let serialNumber = ''+this.printers[key].serial_number +'';
          if(regexp.test(serialNumber)){
            isSearched = true;
            result[result.length] = this.printers[key];
          }
          if(!isSearched){
            let ip = ''+this.printers[key].ip +'';
            if(regexp.test(ip)){
              isSearched = true;
              result[result.length] = this.printers[key];
            }
          }
          if(!isSearched){
            let code = ''+this.printers[key].code +'';
            if(regexp.test(code)){
              isSearched = true;
              result[result.length] = this.printers[key];
            }
          }
          if(!isSearched){
            let address = ''+this.printers[key].printerLocation.address +'';
            if(regexp.test(address)){
              isSearched = true;
              result[result.length] = this.printers[key];
            }
          }
          if(!isSearched){
            let name = ''+this.printers[key].printerLocation.name +'';
            if(regexp.test(name)){
              isSearched = true;
              result[result.length] = this.printers[key];
            }
          }

        }
      }
      return result;
    }
  },
  methods: {
    closeShowNotesPopup(){
      this.showNotesPopup.show = false;
    },
    showShowNotesPopup(notes){
      this.clickedNotes = notes;
      this.showNotesPopup.show = true;
    },
    savePrinter(val){
      let formData = new FormData();
      for(let key in val){
        if( typeof val[key] === 'object'){
          formData.append(key, JSON.stringify(val[key]));
        }else{
          formData.append(key, val[key]);
        }
      }
      axios
          .post(`/v1/printers/sis-update`, formData)
          .then(() => {
            this.closeEditPopup();
            this.getPrinters();
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    editPopupSubmit(){
      this.submitEditPrinter++;
    },
    showEditPopup(obj){
      let copyObj = copyArrObj.copy(obj);
      this.editPrinter = copyObj;
      this.editPrinterPopup.show = true;
    },
    closeEditPopup(){
      this.editPrinterPopup.show = false;
    },
    getPrinters(){
      let formData = new FormData();
      formData.append("selected_franchises", JSON.stringify(this.selectedFranchises));
      axios
          .post(`/v1/printers/sis-get-all`, formData)
          .then(resp => {
            this.printers = resp.data;
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    
  },
  created() {
    this.getPrinters();
  }
};
</script>

<style scoped lang="scss">
.printers--actions {
  display: flex;
  flex-wrap: nowrap;
  justify-content: right;

  .search {
    margin-right: 15px;
  }
}
</style>
